import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'

/**
 * @param {Object} user - Usuario a actualizar
 * @returns {Promise}
 */
export function editUser (user) {
  return axios.put(`${API('users')}?action=update_names`, user, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getJwtString()
    }
  })
}
