export function compareObjects (obj1, obj2) {
  const diff = {}
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      diff[key] = obj2[key]
    }
  }
  return diff
}

export function checkChangedFields (diff, fields) {
  for (const field of fields) {
    if (diff.hasOwnProperty(field)) {
      return true
    }
  }
  return false
}
