import React from 'react'
import './styles.scss'

export default function(props) {
  return (
    <label className="image-radio">
      <input
        type="radio"
        name={props.name}
        value={props.value}
        id={`${props.name}-${props.value}`}
        onChange={props.onChange}
        checked={props.checked}
      />
      <div
        htmlFor={`${props.name}-${props.value}`}
        className={`${props.src} image`}
      />
    </label>
  )
}
