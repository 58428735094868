import React, { useState, useEffect } from 'react'
import { TreeSelect } from 'antd'
import 'antd/es/tree-select/style/css'

export function RankSelect ({
  ranks,
  onChange,
  value,
  placeholder,
  emptyText,
  disabled
}) {
  const [selectData, setSelectData] = useState([])

  // prepara los rangos para mostrar jerarquía
  useEffect(() => {
    if (!ranks || ranks.length === 0) {
      return
    }

    const data = {}
    const forces = {}
    ranks.forEach(item => {
      let row = data[item.hierarchy_id]
      if (!row) {
        row = {
          title: item.hierarchy.name,
          value: item.hierarchy.name,
          selectable: false,
          children: []
        }
      }
      row.children.push({
        title: item.name,
        value: item.id
      })
      data[item.hierarchy_id] = row

      const force = forces[item.hierarchy.military_force]
      if (!force) forces[item.hierarchy.military_force] = {}
      forces[item.hierarchy.military_force][item.hierarchy_id] =
        item.hierarchy.name
    })

    const final = []
    Object.values(forces).forEach(f => {
      Object.keys(f).forEach(i => {
        final.push(data[i])
      })
    })

    if (emptyText) final.unshift({ value: 0, title: emptyText })
    setSelectData(final)
  }, [ranks])

  return (
    <TreeSelect
      disabled={disabled}
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder}
      treeNodeFilterProp='title'
      treeData={selectData}
      value={value}
      onChange={onChange}
    />
  )
}
